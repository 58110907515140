import { Typography } from '@mui/material';
import { memo } from 'react';
import menuItem from 'menu-items';
import NavGroup from './NavGroup';

const MenuList = () => {
    const navItems = menuItem.items.map((item, index) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={index} item={item} />;
            default:
                return (
                    <Typography key={index} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
