import AuthGuard from 'utils/route-guard/AuthGuard';
import CreateSideloadRequest from 'views/sideload';
import ErrorPage from 'views/pages/ErrorPage';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import Redirect from 'views/pages/Redirect';
import { lazy } from 'react';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const ApproverDefault = Loadable(lazy(() => import('views/dashboard/Approver')));
const RequestsDefault = Loadable(lazy(() => import('views/request')));
const RequestSummary = Loadable(lazy(() => import('views/requestsummary')));
const Contracts = Loadable(lazy(() => import('views/contracts')));
const Vendors = Loadable(lazy(() => import('views/vendor')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Redirect />
        },

        {
            path: '/requests',
            element: <DashboardDefault />
        },
        {
            path: '/approvals',
            element: <ApproverDefault />
        },
        {
            path: '/requests/:requestId',
            element: <RequestsDefault />
        },
        {
            path: '/contracts',
            element: <Contracts />
        },
        {
            path: '/vendor',
            element: <Vendors />
        },
        {
            path: '/requestsummary/:requestId',
            element: <RequestSummary />
        },
        {
            path: '/sideload',
            element: <CreateSideloadRequest />
        },
        {
            path: '/*',
            element: <ErrorPage />
        }
    ]
};

export default MainRoutes;
