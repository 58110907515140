import AxiosInstance, { ContentTypeHeader } from './axios.service';

import UrlConstants from '../constants/UrlConstants';

const getAllVendors = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(`${UrlConstants.VENDOR.GET}`, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => reject(err));
    });

const getAllVendorsForProperty = (propertyId) =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(`${UrlConstants.VENDOR.GET}property/${propertyId}`, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => reject(err));
    });

const VendorService = {
    getAllVendors,
    getAllVendorsForProperty
};

export default VendorService;
