import { createContext, useState } from 'react';

import AxiosInstance from 'services/axios.service';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

const PingOneContext = createContext({});

export const PingOneProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser_] = useState({});
    const [isApprover, setIsApprover] = useState(false);

    const setAuthenticationToken = (token) => {
        localStorage.setItem('accessToken', token);
        AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    };

    const verifyToken = (token) => {
        if (!token) {
            return false;
        }
        const decoded = jwtDecode(token);
        return decoded.exp > Date.now() / 1000;
    };

    const handleUserLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('accessToken');
        setUser_({});
        setIsApprover(false);
    };

    const checkIfApprover = (user_) => {
        if (
            user_?.jobCode === 'COMMDIR' ||
            user_?.jobCode === 'MAINTDIR' ||
            user_?.jobCode === 'MDIRIII' ||
            user_?.jobCode === 'OSMMMH' ||
            user_?.jobCode === 'SAFDIR' ||
            user_?.jobCode === 'SRDS' ||
            user_?.jobCode === 'DATAS'
        ) {
            return false;
        }
        if (
            user_?.jobCode === 'DOHMHSS' ||
            user_?.jobCode === 'DIROPS' ||
            user_?.jobCode === 'SRDIROPS' ||
            user_?.jobCode === 'CPSO' ||
            user_?.jobCode === 'VPOPS'
        ) {
            return true;
        }
        return false;
    };

    const setUser = (user_) => {
        setUser_(user_);
        setIsApprover(checkIfApprover(user_));
        setIsAuthenticated(true);
    };

    return (
        <PingOneContext.Provider
            value={{
                setAuthenticationToken,
                setUser,
                verifyToken,
                handleUserLogout,
                isAuthenticated,
                user,
                isApprover,
                checkIfApprover
            }}
        >
            {children}
        </PingOneContext.Provider>
    );
};

PingOneProvider.propTypes = {
    children: PropTypes.any
};

export default PingOneContext;
