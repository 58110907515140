import { FormattedMessage } from 'react-intl';
import { IconFileCertificate } from '@tabler/icons';

const icons = {
    IconFileCertificate
};

const contracts = {
    id: 'menu-item-contracts',
    type: 'group',
    children: [
        {
            id: 'contracts',
            title: <FormattedMessage id="Contracts Dashboard" />,
            type: 'item',
            url: '/contracts',
            icon: icons.IconFileCertificate,
            external: false,
            target: false,
            breadcrumbs: false
        },
        {
            id: 'sideload',
            title: <FormattedMessage id="Sideload Requests" />,
            type: 'item',
            url: '/sideload',
            icon: icons.IconFileCertificate,
            external: false,
            target: false,
            breadcrumbs: false
        }
    ]
};

export default contracts;
