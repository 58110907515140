import { IconFileReport, IconHome } from '@tabler/icons';

import { FormattedMessage } from 'react-intl';

const icons = {
    IconHome,
    IconFileReport
};

const dashboard = {
    id: 'menu-item-dashboard',
    type: 'group',
    children: [
        {
            id: 'requests',
            title: <FormattedMessage id="my-requests" />,
            type: 'item',
            url: 'requests',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'approvals',
            title: <FormattedMessage id="my-approvals" />,
            type: 'item',
            url: 'approvals',
            icon: icons.IconFileReport,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
