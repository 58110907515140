import { Link } from '@mui/material';
import Logo from 'ui-component/Logo';
import { Link as RouterLink } from 'react-router-dom';
import { USER_DASHBOARD_PATH } from 'config';

const LogoSection = () => (
    <Link component={RouterLink} to={USER_DASHBOARD_PATH}>
        <Logo />
    </Link>
);

export default LogoSection;
