import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate({ pathname: 'redirect', search: createSearchParams({ redirect: location.pathname }).toString() }, { replace: true });
        }
    }, [isAuthenticated]);

    return isAuthenticated ? children : null;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
