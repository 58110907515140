const UrlConstants = {
    USER: {
        GET: '/user/'
    },
    REQUEST: {
        CREATE: '/request/',
        CREATE_SIDELOAD: '/request/sideload',
        GET_ALL: '/request/',
        GET_BY_ID: '/request/:requestId',
        GET_ACTIVE_REQUESTS_BY_REPORTER: '/request/reporter/active',
        GET_ACTIVE_REQUESTS_BY_APPROVER: '/request/approver/active',
        APPROVE: '/request/:requestId/stage/:requestStageId/approve',
        REJECT: '/request/:requestId/stage/:requestStageId/reject',
        DASHBOARD_SUMMARY: '/request/dashboard/summary',
        UPLOAD_ARTIFACT: '/request/:requestId/stage/:requestStatusId/upload',
        DOWNLOAD_ARTIFACT: '/request/:requestId/artifact/:artifactId/download',
        GET_ARTIFACTS_FOR_STATUS: '/request/:requestId/:statusId/artifacts',
        GET_TIMELINE: '/request/:requestId/timeline',
        APPROVER_DASHBOARD_SUMMARY: '/request/dashboard/approver-summary',
        GET_ALL_ARTIFACTS: '/request/:requestId/artifacts',
        GET_REQUEST_VENDORS: '/request/:requestId/vendor-prospects'
    },
    CONTRACT: {
        DOWNLOAD_CONTRACT_ARTIFACT: '/contract/:requestId/artifact/download',
        GET_CONTRACTS_BY_REPORTER_AND_STATUS: '/contract/reporter?requestStatus=',
        INPROGRESS_CONTRACTS: '/contract/in-progress'
    },
    PROPERTY: {
        GET: '/property/'
    },
    GLCODE: {
        GET: '/glcode/'
    },
    VENDOR: {
        GET: '/vendor/'
    },

    STAGE_CONFIG: {
        GET: '/stage-config/'
    }
};

export default UrlConstants;
