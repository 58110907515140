import * as yup from 'yup';

import {
    Button,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { useContext, useEffect, useState } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DataContext from 'contexts/DataContext';
import GLCodeService from 'services/glcode.service';
import Loader from 'ui-component/Loader';
import PropertyService from 'services/property.service';
import RequestService from 'services/request.service';
import VendorService from 'services/vendor.service';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

/**
 * 'Enter your email'
 * yup.string Expected 0 arguments, but got 1 */
const validationSchema = yup.object({
    title: yup.string().min(3, 'Title should be of minimum 3 characters length').required('Title is required'),
    description: yup.string().min(8, 'Description should be of minimum 8 characters length').required('Description is required'),
    glcode: yup.string().required('GL Code cannot be empty'),
    property: yup.string().required('Property cannot be empty'),
    type: yup.string().required('Type cannot be empty'),
    vendor: yup.string().required('Vendor is required'),
    endDate: yup.date().required('End Date is required'),
    contractPricing: yup.number().required('Contract Pricing is required')
});

const CreateSideloadRequest = () => {
    const dispatch = useDispatch();
    const { loadRequestToggle, setLoadRequestToggle, setRefresh } = useContext(DataContext);

    const [glCode, setGlCode] = useState([]);
    const [property, setProperty] = useState([]);
    const [vendors, setVendors] = useState([]);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [files, setFiles] = useState('');
    const [filename, setFilename] = useState('');

    const getProperties = () => {
        PropertyService.getAllProperties()
            .then((response) => {
                setProperty(response);
            })
            .catch((err) => {
                console.log(err.message);
                if (err.status === 401) {
                    navigate('/redirect');
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `${err.message}`,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            });
    };

    const getGlCode = () => {
        GLCodeService.getAllGLCodes()
            .then((response) => {
                setGlCode(response);
            })
            .catch((err) => {
                console.log(err.message);
                if (err.status === 401) {
                    navigate('/redirect');
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `${err.message}`,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            });
    };

    const getVendors = () => {
        VendorService.getAllVendors().then((response) => {
            setVendors(response);
        });
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([getProperties(), getGlCode(), getVendors()]).finally(() => {
            setLoading(false);
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            glcode: '',
            property: '',
            type: '',
            vendor: '',
            endDate: '',
            contractPricing: ''
        },
        validationSchema,
        onSubmit: (values, actions) => {
            setLoading(true);
            const formData = new FormData();
            formData.append('contractDocument', files);
            Object.keys(values).forEach((key) => {
                if (key === 'endDate') formData.append(key, new Date(values[key]).toISOString());
                else formData.append(key, values[key]);
            });
            RequestService.createSideloadRequest(formData)
                .then(() => {
                    setFiles('');
                    setFilename('');
                    actions.resetForm();
                    document.getElementById('sideload-file-selector').value = null;
                    setLoadRequestToggle(!loadRequestToggle);
                    setRefresh(true);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Contract submitted successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Unable to create request. Please try later',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                })
                .finally(() => setLoading(false));
        }
    });

    return (
        <>
            {loading && <Loader />}
            <DialogTitle id="form-dialog-title">Sideload Request</DialogTitle>
            <DialogContent>
                <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                    Create a new Vendor Contract Sideload Request
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                id="title"
                                name="title"
                                label="Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ m: 0, width: 1 }} error={formik.touched.glcode && Boolean(formik.errors.glcode)}>
                                <InputLabel id="age-select">GL Code</InputLabel>
                                <Select
                                    id="glcode"
                                    labelId="glcode"
                                    name="glcode"
                                    fullWidth
                                    value={formik.values.glcode}
                                    onChange={formik.handleChange}
                                    label="GL Code"
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {glCode.map((g, index) => (
                                        <MenuItem value={g.id} key={index}>
                                            {g.code}: {g.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.glcode && Boolean(formik.errors.glcode) && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {formik.errors.glcode}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ m: 0, width: 1 }} error={formik.touched.property && Boolean(formik.errors.property)}>
                                <InputLabel id="property-select">Property</InputLabel>
                                <Select
                                    id="property"
                                    labelId="property-select"
                                    name="property"
                                    fullWidth
                                    value={formik.values.property}
                                    onChange={formik.handleChange}
                                    label="Property"
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {property.map((g, index) => (
                                        <MenuItem value={g.id} key={index}>
                                            {g.displayValue}: {g.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.property && Boolean(formik.errors.property) && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {formik.errors.property}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ m: 0, width: 1 }} error={formik.touched.type && Boolean(formik.errors.type)}>
                                <InputLabel id="type">Request Type</InputLabel>
                                <Select
                                    id="type"
                                    labelId="type"
                                    name="type"
                                    fullWidth
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    label="Request Type"
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>

                                    <MenuItem value="SHORT_FORM" key="SHORT_FORM">
                                        Short Form
                                    </MenuItem>
                                    <MenuItem value="LONG_FORM" key="LONG_FORM">
                                        Long Form
                                    </MenuItem>
                                </Select>
                                {formik.touched.type && Boolean(formik.errors.type) && (
                                    <FormHelperText error id="request-type-error-message">
                                        {formik.errors.type}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="contractPricing"
                                name="contractPricing"
                                label="Contract Pricing ($)"
                                value={formik.values.contractPricing}
                                onChange={formik.handleChange}
                                error={formik.touched.contractPricing && Boolean(formik.errors.contractPricing)}
                                helperText={formik.touched.contractPricing && formik.errors.contractPricing}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="vendor-select">Vendor</InputLabel>
                            <FormControl sx={{ m: 0, width: 1 }} error={formik.touched.vendor && Boolean(formik.errors.vendor)}>
                                <Select
                                    id="vendor"
                                    name="vendor"
                                    label="Vendor"
                                    labelId="vendor-select"
                                    value={formik.values.vendor}
                                    onChange={formik.handleChange}
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {vendors.map((vendor, index) => (
                                        <MenuItem value={vendor.id} key={index}>
                                            {vendor.name} ({vendor.vendorID})
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.vendor && Boolean(formik.errors.vendor) && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {formik.errors.vendor}{' '}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <InputLabel id="endDate-select">Contract End Date</InputLabel>
                                <DatePicker
                                    id="endDate"
                                    name="endDate"
                                    renderInput={(props) => (
                                        <TextField
                                            fullWidth
                                            {...props}
                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                            helperText={formik.touched.endDate && formik.errors.endDate}
                                            placeholder="Select Contract End Date"
                                        />
                                    )}
                                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                    helperText={formik.touched.endDate && formik.errors.endDate}
                                    value={formik.values.endDate}
                                    onChange={(value) => formik.setFieldValue('endDate', value, true)}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: formik.touched.endDate && Boolean(formik.errors.endDate),
                                            helperText: formik.touched.endDate && formik.errors.endDate
                                        }
                                    }}
                                    fullWidth
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={8}>
                                <InputLabel id="contract-document-select">Contract Document</InputLabel>
                                <TextField disabled id="filename" name="filename" style={{ width: '700px' }} value={filename} />
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: 5 }}>
                                <Button variant="contained" component="label" disabled={loading}>
                                    Select File
                                    <input
                                        id="sideload-file-selector"
                                        type="file"
                                        hidden
                                        onChange={(e) => {
                                            // console.log('Event Coming');
                                            // setUploadDisabled(false);
                                            if (e.target && e.target.files && e.target.files.length > 0) {
                                                setFiles(e.target.files[0]);
                                                setFilename(e.target.files[0].name);
                                            }
                                        }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                <AnimateButton>
                                    <Button variant="contained" type="submit" disabled={loading || !files}>
                                        {loading ? 'Loading...' : 'Create Contract'}
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </>
    );
};

export default CreateSideloadRequest;
