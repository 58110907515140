import AxiosInstance, { ContentTypeHeader } from './axios.service';

import UrlConstants from '../constants/UrlConstants';

const createRequest = (payload) =>
    new Promise((resolve, reject) => {
        AxiosInstance.post(UrlConstants.REQUEST.CREATE, payload, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const createSideloadRequest = (formData) =>
    new Promise((resolve, reject) => {
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                Accept: 'application/json'
            }
        };
        AxiosInstance.post(UrlConstants.REQUEST.CREATE_SIDELOAD, formData, options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getRequestById = (requestId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.GET_BY_ID.replace(':requestId', requestId);
        AxiosInstance.get(url, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getContractsReportedByUserByStatus = (status) =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(UrlConstants.CONTRACT.GET_CONTRACTS_BY_REPORTER_AND_STATUS + status, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getActiveRequestReportedByUser = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(UrlConstants.REQUEST.GET_ACTIVE_REQUESTS_BY_REPORTER, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getActiveRequestToBeApprovedByUser = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(UrlConstants.REQUEST.GET_ACTIVE_REQUESTS_BY_APPROVER, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getDashboardSummaryForReporter = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(UrlConstants.REQUEST.DASHBOARD_SUMMARY, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const approveRequest = (requestId, requestStageId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.APPROVE.replace(':requestId', requestId).replace(':requestStageId', requestStageId);
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                Accept: 'application/json'
            }
        };
        AxiosInstance.post(url, new FormData(), options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const approveRequestFinalStep = (requestId, requestStageId, formData) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.APPROVE.replace(':requestId', requestId).replace(':requestStageId', requestStageId);
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                Accept: 'application/json'
            }
        };
        AxiosInstance.post(url, formData, options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const rejectRequest = (requestId, requestStageId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.REJECT.replace(':requestId', requestId).replace(':requestStageId', requestStageId);
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                Accept: 'application/json'
            }
        };
        AxiosInstance.post(url, new FormData(), options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const uploadArtifact = (requestId, requestStatusId, formData) =>
    new Promise((resolve, reject) => {
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                Accept: 'application/json'
            }
        };
        const url = UrlConstants.REQUEST.UPLOAD_ARTIFACT.replace(':requestId', requestId).replace(':requestStatusId', requestStatusId);
        AxiosInstance.post(url, formData, options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const downloadContractArtifact = (requestId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.CONTRACT.DOWNLOAD_CONTRACT_ARTIFACT.replace(':requestId', requestId);
        AxiosInstance.get(url, { responseType: 'blob' })
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);
                link.download = response.headers['x-contract-filename'];
                link.click();
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const downloadArtifact = (requestId, artifactId, fileName) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.DOWNLOAD_ARTIFACT.replace(':requestId', requestId).replace(':artifactId', artifactId);
        AxiosInstance.get(url, { responseType: 'blob' })
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);
                link.download = `${fileName}`;
                link.click();
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getArtifactsForRequestByStatus = (requestId, statusId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.GET_ARTIFACTS_FOR_STATUS.replace(':requestId', requestId).replace(':statusId', statusId);
        AxiosInstance.get(url, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getArtifactsForRequestId = (requestId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.GET_ALL_ARTIFACTS.replace(':requestId', requestId);
        AxiosInstance.get(url, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getRequestTimeline = (requestId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.GET_TIMELINE.replace(':requestId', requestId);
        AxiosInstance.get(url, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getDashboardSummaryForApprover = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(UrlConstants.REQUEST.APPROVER_DASHBOARD_SUMMARY, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getRequestVendors = (requestId) =>
    new Promise((resolve, reject) => {
        const url = UrlConstants.REQUEST.GET_REQUEST_VENDORS.replace(':requestId', requestId);
        AxiosInstance.get(url, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const getInProgressContractsForUser = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(UrlConstants.CONTRACT.INPROGRESS_CONTRACTS, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                if (err.response) reject(err.response.data);
                else reject(err);
            });
    });

const RequestService = {
    createRequest,
    createSideloadRequest,
    getRequestById,
    approveRequest,
    approveRequestFinalStep,
    rejectRequest,
    getActiveRequestReportedByUser,
    getDashboardSummaryForReporter,
    getActiveRequestToBeApprovedByUser,
    getContractsReportedByUserByStatus,
    uploadArtifact,
    downloadArtifact,
    downloadContractArtifact,
    getArtifactsForRequestByStatus,
    getRequestTimeline,
    getDashboardSummaryForApprover,
    getArtifactsForRequestId,
    getRequestVendors,
    getInProgressContractsForUser
};

export default RequestService;
