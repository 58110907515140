import AxiosInstance, { ContentTypeHeader } from './axios.service';

import UrlConstants from '../constants/UrlConstants';

const getUserDetails = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(`${UrlConstants.USER.GET}`, { data: {} }, ContentTypeHeader)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err));
    });

const UserService = {
    getUserDetails
};

export default UserService;
