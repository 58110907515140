import axios from 'axios';

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

AxiosInstance.interceptors.response.use(
    (response) => {
        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
            if (response && response.data) Promise.reject(response.data);
            else Promise.reject(response);
        }
        return response;
    },
    (error) => {
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
);

export default AxiosInstance;

export const ContentTypeHeader = {
    headers: {
        'Content-Type': 'application/json'
    }
};
