import PingOneContext from 'contexts/PingOneContext';
import { useContext } from 'react';

const useAuth = () => {
    const context = useContext(PingOneContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useAuth;
