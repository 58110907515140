import { APPROVER_DASHBOARD_PATH, USER_DASHBOARD_PATH } from 'config';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isAuthenticated, isApprover } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            if (isApprover) {
                navigate(APPROVER_DASHBOARD_PATH, { replace: true });
            } else {
                navigate(USER_DASHBOARD_PATH, { replace: true });
            }
        }
    }, []);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
