import { PingOneProvider as AuthProvider } from 'contexts/PingOneContext';
import { DataProvider } from 'contexts/DataContext';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Routes from 'routes';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <DataProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </DataProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
