import * as yup from 'yup';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useContext, useState } from 'react';

import AnimateButton from 'ui-component/extended/AnimateButton';
import DataContext from 'contexts/DataContext';
import GLCodeService from 'services/glcode.service';
import Loader from 'ui-component/Loader';
import PropertyService from 'services/property.service';
import RequestService from 'services/request.service';
import { openSnackbar } from 'store/slices/snackbar';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

/**
 * 'Enter your email'
 * yup.string Expected 0 arguments, but got 1 */
const validationSchema = yup.object({
    title: yup.string().min(3, 'Title should be of minimum 3 characters length').required('Title is required'),
    description: yup.string().min(8, 'Description should be of minimum 8 characters length').required('Description is required'),
    glcode: yup.string().required('GL Code cannot be empty'),
    property: yup.string().required('Property cannot be empty'),
    type: yup.string().required('Type cannot be empty')
});

const CreateRequest = () => {
    const dispatch = useDispatch();
    const { loadRequestToggle, setLoadRequestToggle, setRefresh } = useContext(DataContext);

    const [glCode, setGlCode] = useState([]);
    const [property, setProperty] = useState([]);

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const { user } = useAuth();
    const navigate = useNavigate();

    const getProperties = () => {
        PropertyService.getAllProperties()
            .then((response) => {
                setProperty(response);
            })
            .catch((err) => {
                console.log(err.message);
                if (err.status === 401) {
                    navigate('/redirect');
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `${err.message}`,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            });
    };

    const getGlCode = () => {
        GLCodeService.getAllGLCodes()
            .then((response) => {
                setGlCode(response);
            })
            .catch((err) => {
                console.log(err.message);
                if (err.status === 401) {
                    navigate('/redirect');
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `${err.message}`,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            });
    };

    const handleClickOpen = () => {
        setLoading(true);
        // TODO Catch error and show toast
        Promise.all([getProperties(), getGlCode()]).finally(() => {
            setLoading(false);
            setOpen(true);
        });
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            glcode: '',
            property: '',
            type: '',
            reporter: `${user.firstName} ${user.lastName}`
        },
        validationSchema,
        onSubmit: (values, actions) => {
            setLoading(true);
            delete values.reporter;
            delete values.assignee;
            RequestService.createRequest(values)
                .then(() => {
                    formik.resetForm({ values: '' });
                    setOpen(false);
                    actions.resetForm({ values: '' });
                    setLoadRequestToggle(!loadRequestToggle);
                    setRefresh(true);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Request submitted successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Unable to create request. Please try later',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                })
                .finally(() => setLoading(false));
        }
    });

    const handleClose = () => {
        formik.resetForm({ values: '' });
        setOpen(false);
    };

    return (
        <>
            <Box style={{ marginLeft: '25px' }} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                    Create Request
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {open && (
                    <>
                        {loading && <Loader />}
                        <DialogTitle id="form-dialog-title">Create Request</DialogTitle>
                        <DialogContent>
                            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                                Create a new Vendor Contract Request.
                            </Typography>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            error={formik.touched.title && Boolean(formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="description"
                                            name="description"
                                            label="Description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            error={formik.touched.description && Boolean(formik.errors.description)}
                                            helperText={formik.touched.description && formik.errors.description}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ m: 0, width: 1 }} error={formik.touched.glcode && Boolean(formik.errors.glcode)}>
                                            <InputLabel id="age-select">GL Code</InputLabel>
                                            <Select
                                                id="glcode"
                                                labelId="glcode"
                                                name="glcode"
                                                fullWidth
                                                value={formik.values.glcode}
                                                onChange={formik.handleChange}
                                                label="GL Code"
                                            >
                                                <MenuItem value="">
                                                    <em>Select</em>
                                                </MenuItem>
                                                {glCode.map((g, index) => (
                                                    <MenuItem value={g.id} key={index}>
                                                        {g.code}: {g.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.glcode && Boolean(formik.errors.glcode) && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                                    {formik.errors.glcode}{' '}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            sx={{ m: 0, width: 1 }}
                                            error={formik.touched.property && Boolean(formik.errors.property)}
                                        >
                                            <InputLabel id="property-select">Property</InputLabel>
                                            <Select
                                                id="property"
                                                labelId="property-select"
                                                name="property"
                                                fullWidth
                                                value={formik.values.property}
                                                onChange={formik.handleChange}
                                                label="Property"
                                            >
                                                <MenuItem value="">
                                                    <em>Select</em>
                                                </MenuItem>
                                                {property.map((g, index) => (
                                                    <MenuItem value={g.id} key={index}>
                                                        {g.displayValue}: {g.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.property && Boolean(formik.errors.property) && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                                    {formik.errors.property}{' '}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ m: 0, width: 1 }} error={formik.touched.type && Boolean(formik.errors.type)}>
                                            <InputLabel id="age-select">Request Type</InputLabel>
                                            <Select
                                                id="type"
                                                labelId="type"
                                                name="type"
                                                fullWidth
                                                value={formik.values.type}
                                                onChange={formik.handleChange}
                                                label="Request Type"
                                            >
                                                <MenuItem value="">
                                                    <em>Select</em>
                                                </MenuItem>

                                                <MenuItem value="SHORT_FORM" key="SHORT_FORM">
                                                    Short Form
                                                </MenuItem>
                                                <MenuItem value="LONG_FORM" key="LONG_FORM">
                                                    Long Form
                                                </MenuItem>
                                            </Select>
                                            {formik.touched.type && Boolean(formik.errors.type) && (
                                                <FormHelperText error id="request-type-error-message">
                                                    {formik.errors.type}{' '}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="reporter"
                                            name="reporter"
                                            label="Reporter"
                                            onChange={formik.handleChange}
                                            error={formik.touched.reporter && Boolean(formik.errors.reporter)}
                                            helperText={formik.touched.reporter && formik.errors.reporter}
                                            defaultValue={`${user.firstName} ${user.lastName}`}
                                            fullWidth
                                            disabled
                                            sx={{ display: { xs: 'block' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="assignee"
                                            name="assignee"
                                            label="Assignee"
                                            value={formik.values.assignee}
                                            onChange={formik.handleChange}
                                            error={formik.touched.assignee && Boolean(formik.errors.assignee)}
                                            helperText={formik.touched.assignee && formik.errors.assignee}
                                            defaultValue={`${user.firstName} ${user.lastName}`}
                                            fullWidth
                                            disabled
                                            sx={{ display: { xs: 'block' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                            <AnimateButton>
                                                <Button variant="contained" type="submit" disabled={loading}>
                                                    {loading ? 'Loading...' : 'Submit Request'}
                                                </Button>
                                            </AnimateButton>
                                            <AnimateButton>
                                                <Button variant="contained" onClick={handleClose} disabled={loading}>
                                                    Close
                                                </Button>
                                            </AnimateButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default CreateRequest;
