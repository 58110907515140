import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from 'ui-component/Loader';
import { USER_DASHBOARD_PATH } from 'config';
import UserService from 'services/user.service';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

const Redirect = () => {
    const { isApprover, setAuthenticationToken, verifyToken, setUser, checkIfApprover } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getUserInfo = () => {
        UserService.getUserDetails()
            .then((response) => {
                setUser(response.data);
                if (searchParams.has('redirect')) {
                    navigate(searchParams.get('redirect'), { replace: true });
                } else if (isApprover || checkIfApprover(response.data)) navigate('/approvals', { replace: true });
                else navigate(USER_DASHBOARD_PATH, { replace: true });
            })
            .catch((err) => {
                console.log('Error while setting user info', err);
                localStorage.removeItem('accessToken');
            });
    };

    const parseHash = () =>
        window.location.hash
            .replace('#', '')
            .split('&')
            .reduce((prev, item) => ({ ...prev, [item.split('=')[0]]: decodeURIComponent(item.split('=')[1]) }), {});

    useEffect(() => {
        if (window.location.hash) {
            const hashes = parseHash();
            // Setting the access token in Header for further API calls to use
            setAuthenticationToken(hashes.access_token);
            getUserInfo();
        } else {
            const token = localStorage.getItem('accessToken');
            if (token) {
                if (verifyToken(token)) {
                    setAuthenticationToken(token);
                    getUserInfo();
                } else {
                    localStorage.removeItem('accessToken');
                    window.location.replace(`${process.env.REACT_APP_SSO_TERMINATION_URL}?page=${process.env.REACT_APP_SSO_LOGIN_URL}`);
                }
            } else {
                navigate('/login', { replace: true });
                // window.location.replace(`${process.env.REACT_APP_SSO_URL}${process.env.REACT_APP_SSO_REDIRECT_URL}`);
            }
        }
    }, []);

    return (
        <>
            <Loader />
            <div />
        </>
    );
};

export default Redirect;
