import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';

const Login = Loadable(lazy(() => import('views/pages/Login')));
const Redirect = Loadable(lazy(() => import('views/pages/Redirect')));
const ErrorPage = Loadable(lazy(() => import('views/pages/ErrorPage')));

const LoginRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/redirect',
            element: <Redirect />
        },
        {
            path: '/*',
            element: <ErrorPage />
        }
    ]
};

export default LoginRoutes;
